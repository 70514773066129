import { countries, CountryType } from "../countries";

const countryLabels: string[][] = countries
  .map((country: CountryType) => [country.code, country.label])
  .sort((a, b) => (a[1] > b[1] ? 1 : -1));

export const constants = {
  officialIdentifierTypes: [
    ["National", "National Identity Number"],
    ["Passport", "Passport Number"],
    ["RefugeePassport", "Refugee Passport ID Number"],
    ["Section22AsylymSeekerVisa", "Section 22 Asylym Seeker Visa ID Number"],
    ["Section24RefugeePermit", "Section 24 Refugee Permit File Number"],
  ],
  provinces: [
    ["EasternCape", "Eastern Cape"],
    ["Freestate"],
    ["Gauteng"],
    ["KwaZuluNatal", "KwaZulu-Natal"],
    ["Limpopo"],
    ["Mpumalanga"],
    ["NorthernCape", "Northern Cape"],
    ["NorthWest", "North West"],
    ["WesternCape", "Western Cape"],
  ],
  genders: [
    ["Male"],
    ["Female"],
    ["Other"],
    ["PreferNotToSay", "Prefer Not To Say"],
  ],
  maritalStatuses: [
    ["CivilMarriage", "Civil Marriage"],
    ["CustomaryMarriage", "Customary Marriage"],
    ["Divorced"],
    ["Single"],
    ["Widowed"],
  ],
  countries: countryLabels,
  preferredContactMethods: [
    ["Call", "Phone Call"],
    ["SMS", "SMS"],
    ["WhatsApp", "WhatsApp/messenger"],
    ["Email", "Email"],
  ],
  relationshipToClient: [
    ["Aunt"],
    ["Brother"],
    ["Child"],
    ["Cousin"],
    ["Daughter In-Law"],
    ["Daughter"],
    ["Father In-Law"],
    ["Father"],
    ["Grandchild"],
    ["Granddaughter"],
    ["Grandfather"],
    ["Grandmother"],
    ["Grandparent"],
    ["Grandson"],
    ["Great-Grandfather"],
    ["Great-Grandmother"],
    ["Great-Grandparent"],
    ["Guardian"],
    ["In-Law"],
    ["Mother In-Law"],
    ["Mother"],
    ["Nephew"],
    ["Niece"],
    ["Other"],
    ["Parent"],
    ["Sibling"],
    ["Sister"],
    ["Son In-Law"],
    ["Son"],
    ["Spouse"],
    ["Uncle"],
  ],
};
