export const LegalCaseStates = [
  "Opened",
  "In Progress",
  "Hanging",
  "Pending",
  "Referred",
  "Resolved",
  "Escalated",
  "Closed",
];
